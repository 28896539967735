var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animate"
  }, [_vm._m(0), _c('div', {
    staticClass: "bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5"
  }, [_c('OrderFilter', {
    attrs: {
      "totalRows": _vm.totalRows,
      "paymentOption": _vm.paymentOption,
      "isLoading": _vm.isLoading
    },
    on: {
      "start": _vm.timeStart,
      "end": _vm.timeEnd,
      "paymentgateway": _vm.payment,
      "clientid": _vm.client,
      "ordernumber": _vm.order_number,
      "orderStatus": _vm.order_status,
      "isRenewal": _vm.status_renewal
    }
  }), _c('OrderTable', {
    attrs: {
      "items": _vm.items,
      "isLoading": _vm.isLoading,
      "perPage": _vm.perPage,
      "currentPage": _vm.currentPage
    },
    on: {
      "sort-by": _vm.handleSortBy
    }
  }), _vm.totalRows / _vm.perPage > 1 ? _c('div', {
    staticClass: "flex justify-between ml-1 items-center mt-3"
  }, [_c('div', {
    staticClass: "font-semibold"
  }, [_c('span', {
    staticClass: "text-gray-500 font-normal"
  }, [_vm._v("Showing ")]), _vm._v(" " + _vm._s(_vm.currentPage) + "-" + _vm._s(_vm.perPage) + " "), _c('span', {
    staticClass: "text-gray-500 font-normal"
  }, [_vm._v("of")]), _vm._v(" " + _vm._s(parseInt(_vm.totalRows)) + " ")]), _c('div', {
    staticClass: "mb-2"
  }, [_c('t-pagination', {
    staticClass: "rounded-lg",
    attrs: {
      "total-items": Number(_vm.totalRows),
      "per-page": _vm.perPage
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]) : _vm._e()], 1), _c('div', {
    staticClass: "w-full mt-2"
  }, [_vm.errorMessage ? _c('div', {
    staticClass: "flex justify-center mt-2"
  }, [_c('div', {
    staticClass: "w-full max-w-lg px-4"
  }, [_c('div', {
    staticClass: "bg-red-100 text-red-800 border border-red-400 rounded-lg p-4 mb-4"
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('span', [_vm._v(_vm._s(_vm.errorMessage))]), _c('button', {
    staticClass: "text-red-500 hover:text-red-700",
    on: {
      "click": function ($event) {
        _vm.errorMessage = '';
      }
    }
  }, [_c('svg', {
    staticClass: "bi bi-x-circle",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "fill": "currentColor",
      "viewBox": "0 0 16 16"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16 8a7.977 7.977 0 0 0-2.686-5.686A7.977 7.977 0 0 0 8 0a7.977 7.977 0 0 0-5.686 2.686A7.977 7.977 0 0 0 0 8a7.977 7.977 0 0 0 2.686 5.686A7.977 7.977 0 0 0 8 16a7.977 7.977 0 0 0 5.686-2.686A7.977 7.977 0 0 0 16 8zM8 14a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.293 5.293a1 1 0 1 0-1.414 1.414L8 7.586 6.121 5.707a1 1 0 1 0-1.414 1.414L6.586 9l-2.879 2.879a1 1 0 1 0 1.414 1.414L8 10.414l2.879 2.879a1 1 0 1 0 1.414-1.414L9.414 9l2.879-2.879a1 1 0 0 0 0-1.414z"
    }
  })])])])])])]) : _vm._e(), _vm.isError ? _c('div', {
    staticClass: "flex justify-center mt-2"
  }, [_c('div', {
    staticClass: "w-full max-w-lg px-4"
  }, [_c('div', {
    staticClass: "bg-red-100 text-red-800 border border-red-400 rounded-lg p-4 mb-4"
  }, [_vm._m(1), _c('div', {
    staticClass: "mt-2"
  }, [_c('span', [_vm._v(_vm._s(_vm.errorMessage))])])])])]) : _vm._e()])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5"
  }, [_c('label', [_vm._v("Orders")]), _c('h4', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("All Orders")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('span', [_vm._v("Something went wrong")])]);
}]

export { render, staticRenderFns }