<template>
  <div class="">
      <div class="flex justify-between mt-3">
        <div>
          <div class="relative">
            <svg
          class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          width="20"
          height="20"
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2a8 8 0 016.32 12.906l4.387 4.387a1 1 0 01-1.415 1.415l-4.387-4.387A8 8 0 1110 2zm0 2a6 6 0 100 12 6 6 0 000-12z"
          />
        </svg>
          <input
            v-model="message"
            type="text"
            placeholder="Search Order ID"
            class="rounded-lg bg-gray-50 w-96 border pl-10 py-2"
            @input="onInput"
          />
          <button
          v-if="message"
          class="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
          @click="actionReset"
          title="Clear search"
        >
      ✕
    </button>
          <!-- <span class="absolute left-0 top-12" v-if="typing"> <em> searching.. </em> </span> -->
        </div>
        </div>
        <div>
          <t-dropdown>
            <div
          slot="trigger"
          slot-scope="{ mousedownHandler, focusHandler, blurHandler, keydownHandler }"
          class="flex"
        >
          <button
            class="flex items-center justify-center h-10 px-3 py-2.5 text-gray-500 bg-white border border-gray-400 rounded-lg"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <svg
              class="w-4 h-4 mr-1 text-gray-600 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M5.05 3C3.291 3 2.352 5.024 3.51 6.317l5.422 6.059v4.874c0 .472.227.917.613 1.2l3.069 2.25c1.01.742 2.454.036 2.454-1.2v-7.124l5.422-6.059C21.647 5.024 20.708 3 18.95 3H5.05Z" />
            </svg>
            Filter
            <svg
              class="w-5 h-5 ml-1 text-gray-600 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                points="9.29289322 12.9497475 10 13.6568542 15.6568542 8 14.2426407 6.58578644 10 10.8284271 5.75735931 6.58578644 4.34314575 8"
              />
            </svg>
          </button>
        </div>
        <div class="w-custom bg-white absolute -right-2 pt-2 pb-3 border rounded-lg" slot-scope="{ hide, blurHandler }">
          <div class="block w-full px-4 py-2 text-sm text-gray-500 transition duration-150 ease-in-out focus:outline-none focus:bg-gray-100" role="menuitem">
            <div class="flex justify-between mb-3 mt-2">
              <div>Filter</div>
              <div @click="hide">
                <svg   class="w-4 h-4 text-gray-800 dark:text-white cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
              </svg>
              </div>
            </div>
            <div class="block w-full mb-2">Order Date</div>
            <div class="grid gap-4 grid-cols-2">
               <div>
                <DatetimeCustom :timeDate="false" :label="'Start Date'" v-model="start_date" @blur="blurHandler"></DatetimeCustom>
               </div>
               <div>
                <DatetimeCustom :timeDate="false" :label="'Start Date'" v-model="end_date" @blur="blurHandler"></DatetimeCustom>
               </div>
               <div class="pr-1.5">
                <label class="text-gray-600 font-semibold mb-3">Payment Gateway</label>
                <multiselect
                v-model="payment"
                label="name"
                track-by="name"
                placeholder="double click to get payment gateway"
                open-direction="bottom"
                :options="paymentOption"
                :searchable="true"
                :loading="isLoadingPayment"
                :close-on-select="false"
                :multiple="true"
                @input="changeSelectBox"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
               </div>
               <div class="">
                <label class="text-gray-600 font-semibold mb-3">Order Status</label>
                <FilterComponent
                  v-model="orderStatus"
                  :options="orderStatusOption"
                   :defaultText="'Select Order Status'"
                />
                
               </div>
               <div class="">
                <label class="text-gray-600 font-semibold mb-3">Renewal Status</label>
                <FilterComponent
                  v-model="isRenewal"
                  :options="isRenewalOptions"
                  :defaultText="'Select Renewal Status'"
                />
               </div>
              <div class="">
                <label class="text-gray-600 font-semibold mb-3">Client ID</label>
                <FilterComponent
                  v-model="client"
                  :options="clientOption"
                  :defaultText="'Select Client ID'"
                />
              </div>
            </div>
            <div class="mt-3">
              <button class="border bg-blue-500 px-4 py-2 text-white rounded-lg" @click="searchOrder()" @blur="blurHandler">
                Filter
              </button>
              <button class="border bg-white text-gray-500 px-4 py-2 rounded-lg ml-2" @click="asyncClearFind() " @blur="blurHandler">Reset</button>
            </div>
          </div>
        </div>
          </t-dropdown>
        </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import constant from '../../store/constant';
import DatetimeCustom from '../../components/form/Datetime.vue'
import FilterComponent from '../../components/form/StatusFilterComponent.vue';
const clientID = Object.entries(constant.CLIENT_ID).map((val) => ({
  text: val[0].replace('_', ' '),
  value: val[1],
}));

const orderStatuses = Object.entries(constant.ORDERSTATUS).map((val) => ({
  text: val[0].replace('_', ' '),
  value: val[1],
}));

const statusRenewal = [
  {
    text: 'Renewal',
    value: true,
  },
  {
    text: 'Non Renewal',
    value: false,
  },
];

export default {
  name: 'OrderFilter',
  components: {
    DatetimeCustom,
    FilterComponent
     },
  props: {
    totalRows: {
      type: Number,
      required: true,
    },
    paymentOption: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      buttonShow: false,
      currentPage: 1,
      perPage: 50,
      filter: '',
      items_search: [],
      isLoadingSearch: false,
      isLoadingBtnSearch: false,
      isLodingBtnSearch: false,
      range: {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      isLoadingPayment: false,
      payment: [],
      isLoadingClient: false,
      client: '',
      clientOption: [...clientID],
      message: null,
      typing: null,
      orderStatus: '',
      orderStatusOption: [...orderStatuses],
      isLoadingOrderStatus: false,
      isRenewalOptions: [...statusRenewal],
      isRenewal: '',
      isLoadingRenewal: false,
      start_date:moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    };
  },
  watch: {},
  methods: {
    ...mapActions('orders', [
      'searchOrders',
      'searchOrdersBydate',
      'searchOrderNumber',
    ]),
    onInput() {
       this.typing = 'You are typing';
       clearTimeout(this.debounceTimer);
       this.debounceTimer = setTimeout(() => {
        this.typing = null;
        this.debounceSearch();
      }, 600);
    },
    debounceSearch() {
      this.buttonShow = false;
      this.payment = '';
      this.orderStatus = '';
      this.isRenewal = '';
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
      //  this.message = event.target.value;
        const payload = {
          from: this.range.start,
          to: this.range.end,
          page: this.currentPage,
          limit: this.perPage,
          ordernumber: this.message,
          clientId: this.client?.value,
        };
        this.searchOrderNumber(payload);
      }, 600);
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    changeSelectBox() {
      this.buttonShow = true;
    },
    asyncClearFind() {
     window.location.reload()
    },
    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => {});
    },
    actionReset(){
      window.location.reload()
    },
    searchOrder() {
      this.$emit('start', this.start_date);
      this.$emit('end', this.end_date);
      this.$emit('paymentgateway', this.payment);
      this.$emit('clientid', this.client?.value);
      this.$emit('orderStatus', this.orderStatus?.value);
      this.$emit('isRenewal', this.isRenewal?.value);
      if (this.client?.value != constant.CLIENT_ID.GRAMEDIA) {
        this.message = '';
      }
      
      const payload = {
        page: this.currentPage,
        limit: this.perPage,
        payment: this.payment ? this.payment.map((val) => val.id) : '',
        clientId: this.client,
        orderStatus: this.orderStatus,
        ordernumber: this.message,
        isRenewal: this.isRenewal,
      };
      if (this.start_date) payload.from = moment(this.start_date).format('YYYY-MM-DD');
      if (this.end_date) payload.to = moment(this.end_date).format('YYYY-MM-DD');


      if (this.start_date && this.end_date) {
        const startDate = moment(this.start_date);
        const endDate = moment(this.end_date);

        if (startDate.isAfter(endDate)) {
          alert("Tanggal mulai tidak boleh lebih besar dari tanggal akhir!");
          return;
        }
      }
      this.searchOrdersBydate(payload);
      payload.payment = this.payment
        ? this.payment.map((val) => `${val.id}#${val.name}`)
        : '';
      this.setQuery(payload);
      this.buttonShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.w-custom {
  width: 750px;
}
</style>
