<template>
  <div class="animate">
   <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label>Orders</label>
      <h4 class="text-lg font-semibold">All Orders</h4>
    </div>
  
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <OrderFilter
              @start="timeStart"
              @end="timeEnd"
              @paymentgateway="payment"
              @clientid="client"
              @ordernumber="order_number"
              @orderStatus="order_status"
              @isRenewal="status_renewal"
              :totalRows="totalRows"
              :paymentOption="paymentOption"
              :isLoading="isLoading"
            />
            <OrderTable
              :items="items"
              :isLoading="isLoading"
              :perPage="perPage"
              :currentPage="currentPage"
              @sort-by="handleSortBy"
            />

            <div v-if="totalRows / perPage > 1" class="flex justify-between ml-1 items-center mt-3">
          <div class="font-semibold">
          <span class="text-gray-500 font-normal">Showing </span> {{ currentPage }}-{{ perPage }} <span
            class="text-gray-500 font-normal">of</span> {{ parseInt(totalRows) }}
        </div>
        <div class="mb-2">
          <t-pagination 
          :total-items="Number(totalRows)" 
          :per-page="perPage" 
          v-model="currentPage"
          class="rounded-lg"
          />
        </div>
      </div>
    </div>
  
    <div class="w-full mt-2">
      <!-- Error Message Section -->
      <div v-if="errorMessage" class="flex justify-center mt-2">
        <div class="w-full max-w-lg px-4">
          <div class="bg-red-100 text-red-800 border border-red-400 rounded-lg p-4 mb-4">
            <div class="flex items-center justify-between">
              <span>{{ errorMessage }}</span>
              <button @click="errorMessage = ''" class="text-red-500 hover:text-red-700">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                  <path d="M16 8a7.977 7.977 0 0 0-2.686-5.686A7.977 7.977 0 0 0 8 0a7.977 7.977 0 0 0-5.686 2.686A7.977 7.977 0 0 0 0 8a7.977 7.977 0 0 0 2.686 5.686A7.977 7.977 0 0 0 8 16a7.977 7.977 0 0 0 5.686-2.686A7.977 7.977 0 0 0 16 8zM8 14a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"/>
                  <path d="M10.293 5.293a1 1 0 1 0-1.414 1.414L8 7.586 6.121 5.707a1 1 0 1 0-1.414 1.414L6.586 9l-2.879 2.879a1 1 0 1 0 1.414 1.414L8 10.414l2.879 2.879a1 1 0 1 0 1.414-1.414L9.414 9l2.879-2.879a1 1 0 0 0 0-1.414z"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- General Error Section -->
      <div v-if="isError" class="flex justify-center mt-2">
        <div class="w-full max-w-lg px-4">
          <div class="bg-red-100 text-red-800 border border-red-400 rounded-lg p-4 mb-4">
            <div class="flex items-center justify-between">
              <span>Something went wrong</span>
            </div>
            <div class="mt-2">
              <span>{{ errorMessage }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
// import OrderTable from "../../components/orders/OrderTable.vue"
import OrderFilter from '../../components/orders/OrderFilter.vue';
import OrderTable from './table.vue';
export default {
  name: 'ordersList',
  components: {
    OrderTable,
    OrderFilter,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      sortOrder: [],
      show: false,
      details: [],
      range: {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      
      paymentgatewayID: [],
      clientID: '',
      ordernumber: '',
      orderStatus: '',
      paymentOption: [],
      isRenewal: '',
      dropdownOpen: false,
      selectedOption: { value: "orderNumber", label: "Order Number" }, // Default option
      searchOptions: [
        { value: "orderNumber", label: "Order Number" },
        { value: "orderId", label: "Order ID" },
      ],
      searchQuery: "",
    };
  },
  watch: {
    payments: function() {
      if (!Object.keys(this.payments).length) return;
      this.paymentOption = this.payments;
    },
    currentPage: function() {
      this.actionGetSales();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Transactions | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      payments: (state) => state.payments.items,
      isLoading: (state) => state.orders.isLoading,
      isLodingBtnSearch: (state) => state.orders.isLodingBtnSearch,
      isError: (state) => state.orders.isError,
      totalRows: (state) => state.orders.totalRows,
      errorMessage: (state) => state.orders.ErrorMessage,
    }),
    ...mapGetters('orders', { items: 'orders' }),
  },
  mounted() {
    this.actionGetSales();
    this.actionGetPayment();
  },
  methods: {
    ...mapActions('orders', [
      'fetchOrders',
      'patchOrders',
      'searchOrdersBydate',
    ]),
    ...mapActions('payments', ['fetchAllPayment']),
    actionGetSales() {
      let payload = {
        from: this.range.start,
        to: this.range.end,
        page: this.currentPage,
        limit: this.perPage,
        order_by:this.sortOrder.criteria,
        sort:this.sortOrder.order,
        payment: this.paymentgatewayID
          ? this.paymentgatewayID.map((val) => val.id)
          : '',
        clientId: this.clientID,
        orderStatus: this.orderStatus,
      };
      console.log('payload :',payload)
      this.fetchOrders(payload);
      payload.payment = this.paymentgatewayID
        ? this.paymentgatewayID.map((val) => `${val.id}#${val.name}`)
        : '';
      this.setQuery(payload);
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    handleSortBy(criteria) {
      this.sortOrder = criteria
      this.actionGetSales();
    },
    actionPatchOrder(orders) {
      this.patchOrders(orders);
    },

    actionGetPayment() {
      this.isLoadingPayment = true;
      if (!this.paymentOption.length) {
        const payload = {
          status: true,
        };
        this.fetchAllPayment(payload);
      }

      this.isLoadingPayment = false;
    },

    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => {});
    },

    timeStart(start) {
      this.range.start = moment(start).format('YYYY-MM-DD');
    },
    timeEnd(end) {
      this.range.end = moment(end).format('YYYY-MM-DD');
    },
    payment(paymentgateway) {
      this.paymentgatewayID = paymentgateway;
    },
    client(clientid) {
      this.clientID = clientid;
    },
    order_number(ordernumber) {
      this.ordernumber = ordernumber;
    },
    order_status(orderStatus) {
      this.orderStatus = orderStatus;
    },
    status_renewal(isRenewal) {
      this.isRenewal = isRenewal;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.dropdownOpen = false; // Close dropdown after selecting an option
    },
    onSearch() {
      console.log(`Searching ${this.selectedOption.value} for:`, this.searchQuery);
      this.$emit("search", {
        type: this.selectedOption.value,
        query: this.searchQuery,
      });
    },
  },
};
</script>
<style scoped>
.animate {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
.tableFixHead {
  overflow-y: auto;
  height: 580px;
}
.tableFixHead thead th {
  position: sticky;
  top: 20;
}
.date {
  width: 170px;
}
.border-custom {
  border:#d1d5db;
}
</style>
